import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "mt-5" }
const _hoisted_5 = { class: "box py-5" }
const _hoisted_6 = { class: "flex justify-between mb-6" }
const _hoisted_7 = { class: "flex justify-end items-center space-x-3" }
const _hoisted_8 = { class: "el-dropdown-link" }

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RENEWABLE_ENERGY_TARGET_MANAGEMENT } from "@ems/constants";
import RenewableEnergyTargetModule from "@ems/containers/RenewableEnergyTarget/RenewableEnergyTarget.module";
import { formatLocalDateTime } from "@/utils/helpers/convertDateTime";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { ICreateRenewableTargetDTO } from "@/models/RenewableEnergyTarget";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const STATUS_RENEWABLE = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

const route = useRoute();
const router = useRouter();
const renewableTargetData = computed(
  () => RenewableEnergyTargetModule.listRenewableTargetGetter
);

const isLoadingListRenewableTargetsData = computed(
  () => RenewableEnergyTargetModule.isLoadingListRenewableTargetGetter
);

const handleEdit = async (row: any) => {
  router.push({
    path: `${route.path}/${RENEWABLE_ENERGY_TARGET_MANAGEMENT.PUBLISH_NEW_TARGET.path}`,
    query: { TargetId: `${row.TargetId}` },
  });
};

const handleRedirect = () => {
  router.push(
    `${route.path}/${RENEWABLE_ENERGY_TARGET_MANAGEMENT.PUBLISH_NEW_TARGET.path}`
  );
  RenewableEnergyTargetModule.getRenewableTargetDetailSuccess(
    {} as ICreateRenewableTargetDTO
  );
};
const showButtonSetDashboard = (row: any) => {
  return (
    row.StatusActive == 1 && row.CountryId == null && row.FacilityId == null
  );
};
const handleSetRenewableTarget = async (row: any) => {
  await RenewableEnergyTargetModule.updateRenewableTarget({ id: row.TargetId });
  RenewableEnergyTargetModule.getTargetRenewableTarget();
};
onMounted(() => {
  RenewableEnergyTargetModule.getRenewableTarget();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Renewable Energy Target Management")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-xl 2xl:text-2xl" }, " Renewable Target and Performance Overview ", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn--green-primary",
              onClick: _withModifiers(handleRedirect, ["prevent"])
            }, " Publish New Target ")
          ])
        ]),
        _createElementVNode("div", null, [
          _withDirectives((_openBlock(), _createBlock(_component_el_table, {
            class: "w-full table-custom",
            data: renewableTargetData.value,
            stripe: "",
            height: "66vh",
            "cell-class-name": "text-cafe-dark",
            "header-row-class-name": "tableHeaderRow",
            "row-class-name": "tableRow"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "TargetName",
                label: "Target Name"
              }),
              _createVNode(_component_el_table_column, { label: "Applicable Location" }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.CountryName ? scope.row.CountryName : "All"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "Applicable Facility" }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.FacilityName ? scope.row.FacilityName : "All"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Reporting Standard",
                width: "250"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.StandardType
                    ? "Science-based Target Initiative"
                    : "Carbon Neutral Scope 2"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "Target Percentage" }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.LongTermTargetValue + " %"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "LongTermTargetYear",
                label: "Target Year"
              }),
              _createVNode(_component_el_table_column, { label: "Last update" }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.LastUpdate
                  ? _unref(formatLocalDateTime)(scope.row.LastUpdate)
                  : "NA"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "Status" }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.StatusActive === 1
                    ? STATUS_RENEWABLE.ACTIVE
                    : STATUS_RENEWABLE.INACTIVE), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Action",
                width: "80"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_dropdown, { trigger: "click" }, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_el_dropdown_menu, null, {
                        default: _withCtx(() => [
                          (scope.row.StatusActive === 1)
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 0,
                                onClick: ($event: any) => (handleEdit(scope.row))
                              }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode("Edit")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_el_dropdown_item, null, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Delete ")
                            ])),
                            _: 1
                          }),
                          (showButtonSetDashboard(scope.row))
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 1,
                                onClick: ($event: any) => (handleSetRenewableTarget(scope.row))
                              }, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("Set To Dashboard")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, [
                        _createVNode(BaseSvgIcon, {
                          class: "inline-block hover:text-blue-400 w-5 h-5",
                          name: "edit"
                        })
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, isLoadingListRenewableTargetsData.value]
          ])
        ])
      ])
    ])
  ]))
}
}

})